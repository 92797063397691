import { CookieManager } from '@web_opxp/features';
import urlManager from '../../services/urlManager';
import { ELogoutMode } from './store/types';

export const isLogout = () => {
  const logoutCookie = CookieManager.getCookie(CookieManager.COOKIE_AUTH_LOGOUT);
  const logoutParam = urlManager.getLogout();
  if (!logoutParam && !logoutCookie) {
    return false;
  }
  return logoutParam === null ? ELogoutMode.Default : +logoutParam;
};
