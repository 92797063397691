import find from 'lodash/find';
import { localStorage } from 'web_core_library';
import { AuthState, ERole, IRole, User } from './types';

const BLANK_STATE = {
  userId: null,
  users: {},
};

export const getAuthState = () => {
  const state = localStorage.load('auth') as AuthState;
  return state || BLANK_STATE;
};

export const saveAuthState = (state: AuthState) => localStorage.save('auth', state);

const getCurrentState = () => {
  const { users, userId } = getAuthState();
  if (!userId) {
    return;
  }
  return (users[userId] as User) || undefined;
};

export const getAccessToken = () => {
  const state = getCurrentState();
  return state ? state.accessToken : '';
};

export const getUserId = () => getAuthState().userId;

export const isUserSocial = () => {
  const state = getCurrentState();
  return state ? !!state.social : false;
};

export const resetCurrentUserState = () => {
  const { users, userId } = getAuthState();
  if (!userId || !users[userId]) {
    return false;
  }
  users[userId] = { accessToken: '', email: '', verified: false, userId, premium: false };
  saveAuthState({ userId, users });
};

export const setCurrentUser = (userId: number, user: Partial<User>) => {
  const currentState = getAuthState();
  const existingSession = currentState.users[userId];
  const updatedState: AuthState = {
    userId,
    users: {
      ...currentState.users,
      [userId]: {
        ...existingSession,
        userId,
        ...user,
      },
    },
  };
  saveAuthState(updatedState);
};

export const getRandomPassword = () => Math.random().toString(36).substring(7);

const findRole = (role: ERole, roles?: IRole[]) => find(roles, ['id', role]);

const hasRole = (role: ERole, roles?: IRole[]) => !!findRole(role, roles);

const hasPreAuthenticatedRole = (roles?: IRole[]) => hasRole(ERole.PREAUTHENTICATED, roles);

export const userVerified = (roles?: IRole[]) => !hasPreAuthenticatedRole(roles);

export const userPremium = (roles?: IRole[]) => hasRole(ERole.WEBPREMIUM, roles);

export const getAccessTokenExpiration = (accessToken: string) => {
  try {
    const userData = JSON.parse(window.atob(accessToken.split('.')[1]));
    return parseInt(userData.exp, 10) * 1000;
  } catch (error) {
    return undefined;
  }
};
