import { LanguageService } from '@web_opxp/features';
import includes from 'lodash/includes';
import takeRight from 'lodash/takeRight';
import 'url-polyfill';
import { localStorage } from 'web_core_library';
import { AuthUtils } from '../auth';
import {
  BackUrlWhitelist,
  fallbackUrls,
  KnownUrlParameters,
  OnboardingUrlWhitelist,
  PasswordUrls,
  SigninUrls,
  SignupUrls,
  UrlParameters,
} from '../core/constants';
import { ApiUrl, BlogPath, EdisonPath, SubscribeAppPath, TrainingAppPath } from '../core/env';
import history from './historyService';
import { Themes } from './themeManager';

function addSlashToEnd(str = '') {
  str = str.replace(/\/$/, '');
  return str.indexOf('/?') >= 0 ? str : `${str}/`;
}

export class UrlBuilder {
  private url: URL;

  constructor(customUrl?: string) {
    const url = customUrl || window.location.href;
    this.url = new URL(url);
  }

  public getHostname() {
    return this.url.hostname;
  }

  public getPath() {
    return this.url.pathname;
  }

  public getHash() {
    return this.url.hash;
  }

  public getSearch() {
    return this.url.search;
  }

  public getUrl() {
    return this.url.href;
  }

  public getRedirect(domain: string) {
    const path = this.getPath();
    const hash = this.getHash();
    const search = this.getSearch();
    return `${domain}${path}${search}${hash}`;
  }

  public getPasswordRedirect() {
    const hostname = this.getHostname();
    const theme = urlManager.getTheme();
    const path = PasswordUrls[theme];
    const hash = this.getHash();
    const search = this.getSearch();
    const passwordRedirectUrl = `https://${hostname}${path}${search}${hash}`;
    return passwordRedirectUrl;
  }

  public setSearchParam(param: string, value: string) {
    return this.url.searchParams.set(param, value);
  }

  public getSearchParam(param: string) {
    return this.url.searchParams.get(param);
  }
}

interface SearchParamMap {
  [key: string]: string;
}

// url management class
// strips all search parameters from url and stores them internally for further use
export class UrlManager {
  private params: SearchParamMap;
  public backUrlSecure: boolean;

  constructor() {
    this.params = {};
    this.restore();
    this.loadFromUrl();
    // backUrl fallback
    if (!this.params[UrlParameters.BackUrl]) {
      // if backUrl was empty try to use referrer
      this.params[UrlParameters.BackUrl] = document.referrer || '';
    }
    // backUrl security check
    this.backUrlSecure = this.checkBackUrl();
    // clean url from known parameters and left unknown untoched
    this.cleanUrl();
    // save all params to storage
    this.store();
  }

  private cleanUrl() {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    url.searchParams.forEach((value: string, key: string) => {
      // delete all "known" parameters
      if (KnownUrlParameters.includes(key)) {
        searchParams.delete(key);
      }
    });
    history.replace({
      pathname: history.location.pathname,
      search: `?${searchParams.toString()}`,
    });
  }

  private store() {
    localStorage.save('params', this.params);
  }

  private restore() {
    this.params = localStorage.load('params') || {};
  }

  private loadFromUrl() {
    const url = new URL(window.location.href);
    url.searchParams.forEach((value: string, key: string) => {
      this.params[key] = value;
    });
  }

  private cleanup() {
    localStorage.remove('params');
  }

  private getLowLevelDomain(url: string) {
    // get domain from url
    const domain = new URL(url).hostname;
    // split in parts
    const parts = domain.split('.');
    return parts[0];
  }

  private isSSO(url: string) {
    const domain = this.getLowLevelDomain(url);
    return /sso/i.test(domain);
  }

  private getTopLevelDomain(url: string) {
    // get domain from url
    const domain = new URL(url).hostname;
    // split in parts
    const parts = domain.split('.');
    // take last two domains
    const lastParts = takeRight(parts, 2);
    // compile back to string
    return lastParts.join('.');
  }

  private checkBackUrl() {
    // security check for backUrl
    const backUrl = this.getBackUrl();
    // empty backUrl will be replaced with secure fallback url
    if (!backUrl) {
      return true;
    }
    // check if domain includes sso
    if (this.isSSO(backUrl)) {
      return false;
    }
    // check if domain is present in whitelist
    const topLevelDomain = this.getTopLevelDomain(backUrl);
    return includes(BackUrlWhitelist, topLevelDomain);
  }

  public getFallbackUrl() {
    // onboardingVer < 9
    // BW-5116: return opxp as fallback when no onboarding is set
    return fallbackUrls[Themes.Opxp];
  }

  public isUrlInOnboardingWhitelist(url: string) {
    if (!url) {
      return false;
    }
    const domain = new URL(url).hostname;
    return includes(OnboardingUrlWhitelist, domain);
  }

  private isSubscribeUrl(url: string) {
    if (!url) {
      return false;
    }
    const urlModel = new URL(url);
    const path = urlModel.pathname;
    const domain = urlModel.hostname;
    return /\/subscribe/i.test(path) || /\/subscribe/i.test(domain);
  }

  private isBlogUrl(url: string) {
    if (!url) {
      return false;
    }
    const urlModel = new URL(url);
    const path = urlModel.pathname;
    const domain = urlModel.hostname;
    return /\/science/i.test(path) || /\/blog/i.test(domain);
  }

  public isEdisonUrl(url: string) {
    if (!url) {
      return false;
    }
    const domain = new URL(url).hostname;
    const edisonDomain = new URL(EdisonPath).hostname;
    return domain === edisonDomain;
  }

  public getBackUrlWithFallback() {
    const backUrl = this.getBackUrl();
    const fallbackUrl = this.getFallbackUrl();
    return backUrl ? backUrl : fallbackUrl;
  }

  // generic getter
  public getSearchParam(param: string) {
    return this.params[param] || null;
  }

  // generic remove handler
  public clearSearchParam(param: string) {
    if (this.params[param]) {
      delete this.params[param];
      this.store();
    }
  }

  public getUrlSearchParams() {
    return new URLSearchParams(this.params);
  }

  // getters for particular parameters
  public getAccessToken() {
    return this.getSearchParam(UrlParameters.AccessToken);
  }

  public getSessionToken() {
    return this.getSearchParam(UrlParameters.SessionToken);
  }

  public getTheme() {
    const pathname = window.location.pathname;
    const isOpxpPath = /\/opxp\//i.test(pathname);
    const isCouponPage = /\/coupon/i.test(pathname);
    const isMobilePage = /\/mobile\//i.test(pathname);
    const forceOpxp = isOpxpPath || isCouponPage || isMobilePage;
    const theme = forceOpxp ? Themes.Opxp : this.getThemeFromSearchParams();
    return theme;
  }

  public getThemeFromSearchParams() {
    const searchValue = this.getSearchParam(UrlParameters.Theme);
    if (!searchValue) {
      return Themes.Default;
    }
    switch (searchValue.toLowerCase()) {
      case 'opxp':
        return Themes.Opxp;
      case 'edison':
        return Themes.Edison;
      default:
        return Themes.Default;
    }
  }

  public getLogout() {
    return this.getSearchParam(UrlParameters.Logout);
  }

  public getBackUrl() {
    return this.getSearchParam(UrlParameters.BackUrl) || '';
  }

  public getForwarding() {
    return this.getSearchParam(UrlParameters.Forwarding);
  }

  public getReturnTo() {
    return this.getSearchParam(UrlParameters.ReturnTo);
  }

  public getObVariant() {
    return this.getSearchParam(UrlParameters.ObVariant);
  }

  public getMascotVariant = () => {
    return this.getSearchParam(UrlParameters.MascotVariant);
  };

  public getObFlow = () => {
    return this.getSearchParam(UrlParameters.OBFlow);
  };

  public getSignupUrl() {
    return SignupUrls[this.getTheme()] || SignupUrls.default;
  }

  public getSigninUrl() {
    return SigninUrls[this.getTheme()] || SigninUrls.default;
  }

  public getLogoutDiscountUrl() {
    return '/logoutDiscount';
  }

  // clean params from storage before going out
  public redirect(url: string) {
    this.cleanup();
    window.location.assign(url);
  }

  // clean access token from params
  public removeAccessToken() {
    this.clearSearchParam(UrlParameters.AccessToken);
  }

  public reload() {
    this.loadFromUrl();
    // backUrl security check
    this.backUrlSecure = this.checkBackUrl();
    this.store();
  }

  public removeLogout() {
    this.clearSearchParam(UrlParameters.Logout);
  }
}

export const urlBuilder = new UrlBuilder();
const urlManager = new UrlManager();

export function createPasswordBackUrl() {
  const urlBuilder = new UrlBuilder();
  const referrerUrl = urlManager.getBackUrl();
  referrerUrl && urlBuilder.setSearchParam('backUrl', referrerUrl);
  const theme = urlManager.getTheme();
  urlBuilder.setSearchParam('theme', theme);
  const lang = LanguageService.getLanguage() || 'de';
  lang && urlBuilder.setSearchParam('lang', lang);
  const redirectUrl = urlBuilder.getPasswordRedirect();
  return redirectUrl;
}

export function getZendeskUrl(language: string, session?: string) {
  const locale = language === 'en' ? 'en-us' : language;
  const tokenParam = session ? `?signin=${session}` : '';
  return `${ApiUrl}/zendesk/${locale}${tokenParam}`;
}

export const getBlogUrl = (lang: string) => `${BlogPath}/${lang}`;

export const getEffectivenessUrl = (lang: string) => {
  const blogUrl = getBlogUrl(lang);
  let path = '';
  switch (lang) {
    case 'en':
      path = '/bulletproof-brain-training-study-proves-effectiveness-of-neuronation-premium/';
      break;
    case 'de':
      path = '/neue-studie-beweist-neuronation-mit-hoher-signifikanz-effektiv/';
      break;
    case 'fr':
      path = '/resultats-detude-neuronation-ameliore-la-memoire/';
      break;
    case 'ru':
      path =
        '/%d1%80%d0%b5%d0%b7%d1%83%d0%bb%d1%8c%d1%82%d0%b0%d1%82%d1%8b-%d0%b8%d1%81%d1%81%d0%bb%d0%b5%d0%b4%d0%be%d0%b2%d0%b0%d0%bd%d0%b8%d1%8f-neuronation-%d1%83%d0%bb%d1%83%d1%87%d1%88%d0%b0%d0%b5%d1%82/';
      break;
  }
  return addSlashToEnd(`${blogUrl}${path}`);
};

export const getTrainingUrl = (session: string, lang: string) => {
  const urlBuilder = new UrlBuilder(addSlashToEnd(TrainingAppPath));
  urlBuilder.setSearchParam('session', session);
  urlBuilder.setSearchParam('lang', lang);
  return urlBuilder.getUrl();
};

export const getSubscribeUrl = (lang: string, coupon?: string) => {
  const urlBuilder = new UrlBuilder(addSlashToEnd(SubscribeAppPath));
  urlBuilder.setSearchParam('lang', lang);
  if (coupon) {
    urlBuilder.setSearchParam('coupon', coupon);
  }
  return urlBuilder.getUrl();
};

export const getPrivacyPolicyUrl = (lang: string) => `/legals/dataprivacy/?theme=opxp&lang=${lang}`;

export const redirectBack = (session?: string) => {
  // try to redirect to backUrl if anything found in url
  const backUrl = urlManager.getBackUrlWithFallback();
  if (!backUrl) {
    return;
  }
  const url = new UrlBuilder(backUrl);
  const forwardAuth = urlManager.getForwarding();
  // apply forwarding if a parameter given or if legacy url is set as backurl
  const shouldApplyForwarding = forwardAuth;
  if (shouldApplyForwarding) {
    const token = AuthUtils.getAccessToken();
    // append `?signin=my-access-token` to the url
    url.setSearchParam(UrlParameters.AccessToken, token);
  }
  // add return_to if present
  const returnTo = urlManager.getReturnTo();
  if (returnTo) {
    url.setSearchParam(UrlParameters.ReturnTo, returnTo);
  }
  // add session token to url
  if (session) {
    url.setSearchParam(UrlParameters.SessionToken, session);
  }
  // See BW-3203
  // user might change language while on sso
  // but backUrl does not have language, so it will use one defined before
  // so we change language if it was changed since initial visit
  const langDefined = urlManager.getSearchParam('lang');
  const currentLang = LanguageService.getLanguage();
  if (langDefined && currentLang && langDefined !== currentLang) {
    url.setSearchParam('lang', currentLang);
  }
  // BW-6795 issue workaround: always set language for redirects to opxp
  const isNeuroNation = urlManager.isUrlInOnboardingWhitelist(backUrl);
  if (isNeuroNation && !!currentLang) {
    url.setSearchParam('lang', currentLang);
  }
  const finalRedirectUrl = url.getUrl();
  urlManager.redirect(finalRedirectUrl);
};

export const getJobsUrl = () => {
  const jobsUrl = new URL('https://neuronation.breezy.hr/');
  return jobsUrl.toString();
};

export default urlManager;

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
const isDevDomain = Boolean(window.location.hostname.match(/heroku|dev/));

export const isProduction = !isLocalhost && !isDevDomain;
