import { ELanguage } from '@web_opxp/features';
import * as Env from '../core/env';
import { Themes } from '../services/themeManager';

// known http status codes
export const HttpStatusCode = {
  Created: 201,
  Forbidden: 403,
  InternalError: 0,
  InvalidData: 422,
  MethodNotAllowed: 405,
  NoContent: 204,
  NotFound: 404,
  Ok: 200,
  ServiceUnavailable: 503,
  TokenExpired: 412,
  TooManyRequests: 429,
  Unauthorized: 401,
  Unknown: 500,
};

export const UrlParameters = {
  // access token parameter - deprecated
  AccessToken: 'signin',
  // url where to return after login
  BackUrl: 'backUrl',
  // add signin to backUrl
  Forwarding: 'forwardAuth',
  // logout current user
  Logout: 'logout',
  // session token parameter
  SessionToken: 'session',
  // styling theme
  Theme: 'theme',
  // zendesk return_to parameter
  ReturnTo: 'return_to',
  // ob variant
  ObVariant: 'ob',
  // sso page variant
  SSOPage: 'sso',
  // mascot variant,
  MascotVariant: 'msct',
  // new ob flow approach
  OBFlow: 'ob_flow',
};

// all known non-tracking parameters which must be removed from url
export const KnownUrlParameters = [
  UrlParameters.AccessToken,
  UrlParameters.BackUrl,
  UrlParameters.Forwarding,
  UrlParameters.Logout,
  UrlParameters.ReturnTo,
  UrlParameters.SessionToken,
  UrlParameters.Theme,
  UrlParameters.ObVariant,
];

export const SignupUrls = {
  default: '/signup',
  [Themes.Edison]: '/signup',
  [Themes.Opxp]: '/opxp/signup',
};

export const SigninUrls = {
  default: '/',
  [Themes.Edison]: '/',
  [Themes.Opxp]: '/opxp/login',
};

export const PasswordUrls = {
  default: '/password',
  [Themes.Edison]: '/password',
  [Themes.Opxp]: '/opxp/password',
};

export const ForgotUrls = {
  default: '/forgot',
  [Themes.Edison]: '/forgot',
  [Themes.Opxp]: '/opxp/forgot',
};

export const LogoutUrls = {
  default: '/logout',
  [Themes.Edison]: '/logout',
  [Themes.Default]: '/logout',
  [Themes.Opxp]: '/opxp/logout',
};

export const fallbackUrls = {
  [Themes.Edison]: Env.EdisonPath,
  [Themes.Opxp]: Env.OnboardingAppPath,
  [Themes.Default]: Env.LegacyPath,
};

const customWhitelist = Env.BackUrlWhitelist;
export const BackUrlWhitelist = [
  'neuronation.com',
  'neuronation.de',
  'neuronation.fr',
  'neuronation.ru',
  ...(customWhitelist ? customWhitelist.split(',') : []),
];

export const OnboardingUrlWhitelist = Env.OnboardingUrlWhitelist ? Env.OnboardingUrlWhitelist.split(',') : [];

export enum ELogoutMode {
  // default mode - redirect to backUrl after successful logout
  Default = 1,
  // stop and wait for login
  WaitForLogin,
}

// constants storing user stats properties for different stats used by the project
export const NEWSLETTER_STAT = '4-8-0';

export const SSO_AVAILABLE_LANGUAGES = [
  ELanguage.German,
  ELanguage.English,
  ELanguage.French,
  ELanguage.Russian,
  ELanguage.Japanese,
  ELanguage.Spanish,
  ELanguage.Italian,
  ELanguage.Polish,
];
